import React, {useContext, useEffect, useState} from "react";
import {INotificationContext, NotificationContext} from "../../../contexts/notificationContext";
import {Alert, AlertTitle, Snackbar} from "@mui/material";

export const NotifyBar = () => {
  const notify : INotificationContext = {
    title : "",
    description : "",
    severity : "info"
  }
  const notificationContext = useContext(NotificationContext);
  const [getNotify, setNotify] = useState<INotificationContext | undefined>(notify);

  useEffect(() => {

    if(notificationContext === undefined)
      return;

    setInterval(() => {
      if(notificationContext !== undefined)
        return;
      closingSnackBar();
    },6000);
  },[notificationContext]);

  const canOpenSnackBar = () : boolean => {
    return getNotify !== undefined && getNotify.description !== "" && getNotify.description !== undefined;
  }

  const closingSnackBar = () : void => {
    notificationContext.setNotification?.("","","info");
    setNotify(undefined);
  }

  useEffect(() => {
    setNotify(notificationContext);
  },[notificationContext]);

  return(
      <Snackbar open={canOpenSnackBar()} autoHideDuration={6000} onClose={closingSnackBar}>
        { canOpenSnackBar()
            ?
            <Alert severity={getNotify?.severity}>
              <AlertTitle>{getNotify?.title}</AlertTitle>
              {getNotify?.description}
            </Alert>
            : <div/>
        }
      </Snackbar>
  )
}