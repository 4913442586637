import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IAuthData {
    jwt : string,
    email : string,
    id : string
}
const userSavedJson = localStorage.getItem("user");
const initialState : IAuthData =
    userSavedJson !== null
        ? JSON.parse(userSavedJson)
        : {email: "", jwt: "", id: ""};

export const authSlice = createSlice({
    name : "auth",
    initialState : initialState,
    reducers : {
        login : (state , action : PayloadAction<IAuthData>) : void => {
            // @ts-ignore
            state.value = action.payload;
            localStorage.setItem("user",JSON.stringify(action.payload));
        },
        logout : (state) : void => {
            localStorage.removeItem("user");
        }
    }
});

export const {login, logout} = authSlice.actions;

export default authSlice.reducer;