import Text from "../../atoms/text/text";
import {InputField} from "../../atoms/inputField/inputField";
import {CustomButton} from "../../atoms/customButton/customButton";
import {TextButton} from "../../atoms/textButton/textButton";
import {Panel} from "../../molecules/panel/panel";
import React, {ChangeEvent, CSSProperties, useContext, useEffect, useRef, useState} from "react";
import {LoginPageState, LoginSignupContext} from "../../../contexts/loginSignupContext";
import './styles.css';
import {AutoContainer, InlineColumn, InlineRow} from "../../atoms/autoContainer/autoContainer";
import {Requests} from "../../../utils/Requests";
import {RegisterRequestDto} from "../../../dto/auth/RegisterRequestDto";
import {RegisterResponseDto} from "../../../dto/auth/RegisterResponseDto";
import {NotificationContext} from "../../../contexts/notificationContext";
import {StringUtils} from "../../../utils/stringUtils";
export const RegisterPanel = () => {

    const loginContext = useContext(LoginSignupContext);
    const notificationContext = useContext(NotificationContext);

    const name = useRef("");
    const surname = useRef("");
    const age = useRef("");
    const city = useRef("");
    const email = useRef("");
    const password = useRef("");

    const titleStyle : CSSProperties = {
        fontSize: "32px",
        color : "white",
        lineHeight: "76px",
        fontWeight: "800",
        letterSpacing: "0.035em",
        marginTop: "0.35em",
        marginBottom: "0.4em"
    }

    const modifiedRowStyle = {
        ...InlineRow,
        columnGap : "0px",
        column : 2
    }

    useEffect(() => {
        const listener = async (event: { code: string; preventDefault: () => void; }) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                await doRegister();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const setNameState = (event : ChangeEvent<string>) => {
        // @ts-ignore
        name.current = (event.target.value);
    }

    const setSurnameState = (event : ChangeEvent<string>) => {
        // @ts-ignore
        surname.current = (event.target.value);
    }

    const setAgeState = (event : ChangeEvent<string>) => {
        // @ts-ignore
        age.current = (event.target.value);
    }

    const setCityState =  (event : ChangeEvent<string>) => {
        // @ts-ignore
        city.current = (event.target.value);
    }

    const setEmailState = (event : ChangeEvent<string>) => {
        // @ts-ignore
        email.current = (event.target.value);
    }

    const setPasswordState = (event : ChangeEvent<string>) => {
        // @ts-ignore
        password.current = (event.target.value);
    }

    const switchPageState = () => {
        loginContext.switchContext(LoginPageState.login);
    }

    const doRegister = async () => {

        if(!StringUtils.emailValidation(email.current)) {
            notificationContext.setNotification?.("Errore","Email non valida", "error");
            return;
        }

        const data : RegisterRequestDto = {
            age : Number.parseInt(age.current),
            city : city.current,
            password : password.current,
            roleId : "6234e5a04d2d1f7781c2aa41",
            email : email.current,
            username : name.current + "_" + surname.current
        }

        await Requests.post<RegisterResponseDto>(Requests.register,data)
            .then(response => {
                if(response === undefined)
                    return;
                notificationContext.setNotification?.("Registrazione effettuata","Nuovo utente creato", "success");
            });
    }

    return(
        <Panel>
            <Text text={"[REGISTRAZIONE]"} fontSize={34} color={"#FFFFFF"} style={titleStyle}/>
            <AutoContainer useInlineStyle={true} style={modifiedRowStyle}>
                <InputField cssId={"Name"} type={"text"} textTop={"Nome"} onChange={setNameState}/>
                <InputField cssId={"Surname"} type={"text"} textTop={"Cognome"} onChange={setSurnameState}/>
            </AutoContainer>
            <AutoContainer useInlineStyle={true} style={InlineRow}>
                <InputField cssId={"Age"} type={"number"} textTop={"Età"} onChange={setAgeState}/>
                <InputField cssId={"City"} type={"text"} textTop={"Città"} onChange={setCityState}/>
            </AutoContainer>
            <AutoContainer useInlineStyle={true} style={InlineColumn}>
                <InputField type={"text"} textTop={"Email"} onChange={setEmailState}/>
                <InputField type={"text"} textTop={"Password"} onChange={setPasswordState}/>
            </AutoContainer>
            <AutoContainer useInlineStyle={true} style={InlineColumn}>
                <CustomButton cssId={"RegisterButton"} text={"REGISTRATI"} fontSize={14} textColor={"white"} onClick={async () => doRegister()}/>
                <TextButton text={"ACCEDI"}
                            fontSize={12}
                            color={"white"}
                            onClick={switchPageState}/>
            </AutoContainer>
        </Panel>
    );
}