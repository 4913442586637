import React, {useEffect, useRef, useState} from 'react';
import {ClickAwayListener, Menu, Stack} from "@mui/material";
import {ProfileButton} from "../../atoms/profileButton/profileButton";
import {TextButton} from "../../atoms/textButton/textButton";
import "./styles.css";
import {useSelector} from "react-redux";
import {RootState} from "../../../index";
import {UserDto} from "../../../dto/user/userDto";
import {Requests} from "../../../utils/Requests";

export interface IProfileHamburgerProps {
    onClick : any,
    name ?: string,
    menuItems : Array<React.ReactElement>
}

export const ProfileHamburger = (props : IProfileHamburgerProps) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>("");

    const stackRef = useRef<Element>();
    // @ts-ignore
    const authSelector = useSelector((state: RootState) => state.auth.value);

    useEffect(() => {
        if(!props.name) {
            getUsername();
        }
        else {
            setName(props.name)
        }
    },[props.name]);

    const getUsername = async () => {

        if(!authSelector || !authSelector.id){
            return;
        }

        const user = await Requests.get<UserDto>(Requests.userController + authSelector?.id);

      setName(user.data.username);
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);

        if (props.onClick)
            props?.onClick();
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Stack className={"StackHamburger"}
                   ref={stackRef}
                   aria-controls={"profile_hamburger_menu"}
                   spacing={1}
                   flexDirection={"row"}
                   alignContent={"center"}
                   alignItems={"center"}
                   justifyContent={"center"}
                   onPointerDown={handleClick}
                   sx={{
                       '&:hover': {
                           cursor: 'pointer'
                       }
                   }}
            >
                <ProfileButton name={name}
                               onClick={null}/>
                <TextButton text={name}
                            fontSize={"0.85rem"}
                            color={"white"}
                            onClick={undefined}
                />
                <Menu
                    id="profile_hamburger_menu"
                    anchorEl={anchorEl}
                    container={stackRef.current}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 50,
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{
                        '& .MuiBackdrop-root': {
                            width: 0
                        }
                    }}
                >
                    {props.menuItems}
                </Menu>
            </Stack>
        </ClickAwayListener>
    );
}
