import React, {CSSProperties} from 'react';

import './styles.css';

export interface IPropsPanel {
  children : any,
  style ?: CSSProperties,
}

export const Panel = (props : IPropsPanel) => {
  if (props.style === undefined)
    return (
        <div className={"Panel"}>
            {props.children}
        </div>
    );
  return (
      <div style={props.style}>
          {props.children}
      </div>
  );
}

