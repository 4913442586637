import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ResponseVideoActionContainerDto} from "../../dto/video/responseVideoActionContainerDto";

export interface IVideoData {
    data: ResponseVideoActionContainerDto
}

const initialState : IVideoData = {
    data : {
        id : "",
        startWithActionId : "",
        version : -1,
        videoName : "",
        actionsVideo : [],
        imageId : ""
    }
}

export const videoSlice = createSlice({
    name : "videoPlayer",
    initialState : initialState,
    reducers : {
        configureVideo : (state, action : PayloadAction<IVideoData>) : void => {
            // @ts-ignore
            state.value = action.payload;
        }
    }
});

export const {configureVideo} = videoSlice.actions;

export default videoSlice.reducer;