import {MouseEventHandler} from "react";
import Text from "../text/text";
import "./styles.css";

export interface ITextButtonProps {
    text : string
    fontSize : number | string
    onClick : MouseEventHandler<HTMLButtonElement> | undefined,
    color : string
}

export const TextButton = (props : ITextButtonProps) => {
    return(
        <button className={"TextButton"} onClick={props?.onClick}>
            <Text text={props.text} fontSize={props.fontSize} color={props.color}/>
        </button>
    )
}
