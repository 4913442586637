import React from 'react';
import {Card, CardContent, CardMedia, SxProps, Theme} from "@mui/material";
import './styles.css';
import Text from "../text/text";

export interface ICustomProps {
    title : string,
    image : string,
    onClick : () => void;
}

export const cardTheme : SxProps<Theme> = {
    maxHeight : "12rem",
    maxWidth : "9rem",
    marginLeft : "2rem",
    marginRight : "r2em"
}

export const contentTheme : SxProps<Theme> = {
    maxHeight : "8rem",
    height : "5rem",
    width : "100%",
    backgroundColor : "black",
    bottom: 0
}


export const CustomCard = (props : ICustomProps) => {
  return(
     <Card sx={cardTheme} onClick={props.onClick}>
        <CardMedia component={"img"}
                   image={props.image}
        />
         <CardContent sx={contentTheme}>
             <Text text={props.title} fontSize={15}/>
         </CardContent>
     </Card>

  )
}

// <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxcMkbuuTaN4ydo_bsQud_BCzCrAfqEn9ZVA&usqp=CAU"}/>
//