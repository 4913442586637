import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {IAuthData} from "../reducers/Auth/authReducer";

export class Requests {

    public static login = "api/Auth/login";
    public static register = "api/Auth/register";
    public static checkJwt = "api/Auth/check";
    public static videoActionController = "api/VideoAction/";
    public static videoActionContainerController = "api/VideoActionContainer/"
    public static contentController = "api/content/";
    public static userController = "api/User/";
    public static backendUrl = "https://logicscriptinteractive.com/logicScript/";

    static async get<T>(endpoint: string, auth?: IAuthData,config ?: AxiosRequestConfig): Promise<AxiosResponse<T>> {

        if(config === undefined && auth !== undefined) {
            config = {
                headers: {
                    "Authorization": `Bearer ${auth?.jwt}`
                }
            }
        }

        const url = this.backendUrl + endpoint;
        console.log("Doing GET request to " + url);
        return auth ? await axios.get(url,config) : await axios.get(this.backendUrl + endpoint);
    }

    static async post<T>(endpoint: string, data: any, auth ?: IAuthData): Promise<AxiosResponse<T>> {

        const url = this.backendUrl + endpoint;
        console.log("Doing POST request to " + url);
        return auth ? await axios.post(url, data, {
            headers: {
                "Authorization": `Bearer ${auth.jwt}`
            }
        }) : await axios.post(this.backendUrl + endpoint, data);
    }
}