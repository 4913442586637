import React, {useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginPage from "./pages/loginPage/loginPage";
import {LoginPageState, LoginSignupContext} from "./contexts/loginSignupContext";
import {AxiosInterceptor} from "./utils/axiosInterceptor";
import {INotificationContext, NotificationContext} from "./contexts/notificationContext";
import {NotifyBar} from "./components/organisms/notifyBar/notifyBar";
import {AlertColor} from "@mui/material/Alert/Alert";
import {VideoList} from "./pages/videoList/videoList";
import {VideoPage} from "./pages/videoPage/videoPage";
import {FaceDetectorPageTest} from "./pages/faceDetectorPageTest";

function App() {

    const [loginState, setLoginState] = useState(LoginPageState.login);
    const [getNotify, setNotify] = useState<INotificationContext>();

    const notifyAll = (title: string, description: string, severity: AlertColor) => {
        setNotify({
            title: title,
            severity: severity,
            description: description
        });
    }

    return (
        <NotificationContext.Provider
            value={{
                title: getNotify?.title,
                description: getNotify?.description,
                severity: getNotify?.severity,
                setNotification: notifyAll
            }}>
            <LoginSignupContext.Provider value={{state: loginState, switchContext: setLoginState}}>
                <AxiosInterceptor>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<LoginPage/>}/>
                            <Route path="/videoList" element={<VideoList/>}/>
                            <Route path="/videoPlayer"  element={<VideoPage/>}/>
                            <Route path={"/face"} element={<FaceDetectorPageTest/>}/>
                        </Routes>
                    </BrowserRouter>
                    <NotifyBar/>
                </AxiosInterceptor>
            </LoginSignupContext.Provider>
        </NotificationContext.Provider>
    );
}

export default App;
