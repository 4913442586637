import "./styles.css";
import Text from "../text/text";
import {CSSProperties} from "react";
import {NumberUtils} from "../../../utils/numberUtils";

export interface ICustomButtonProps {
    text : string;
    fontSize : number | string;
    textColor ? : string;
    cssId ?: string
    textStyle ?: CSSProperties;
    onClick ? : () => {}
}

export const CustomButton = (props : ICustomButtonProps) => {
    return(
        <button key={props.text + NumberUtils.generateRandomNumber(-9999,9999).toString()} id={props.cssId} className={"CustomButton"} onClick={props.onClick}>
            <Text text={props.text} fontSize={props.fontSize} color={props.textColor} style={props.textStyle}/>
        </button>
    )
}
