import ReactPlayer from "react-player/lazy";
import {
    SkipNext
} from "@mui/icons-material";

import {AutoContainer, InlineColumn, InlineRow} from "../../atoms/autoContainer/autoContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./styles.css";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
interface IVideoPlayerConfiguration {
    url : string,
    title : string,
    isFullScreen : boolean,
    goToChooseEnabled : boolean,
    choosableButtons : JSX.Element[],
    onGoToChoose : any,
    onVideoReady : any,
    onVideoEnded : any,
    goFullScreen: any,
    exitFullScreen: any
}

const choosableButtonsContainerMobile : React.CSSProperties = {
    display: "flex !important",
    flexDirection: "row",
    alignContent: "center !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    justifyItems: "center !important",
    height: "5em !important",
    width: "100% !important",
    position: "absolute",
    marginBottom: "5rem",
    bottom: 0,
    left:0,
    right:0
}

const choosableButtonsContainerNormal : React.CSSProperties = {
    display: "flex !important",
    flexDirection: "row",
    alignContent: "center !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    justifyItems: "center !important",
    height: "5em !important",
    width: "100% !important",
    position: "absolute",
    marginBottom: "5rem",
    bottom: 0,
    left:0,
    right:0
}

type styleState = {
    videoBarstate : "videoBar" | "videoBarClosed",
    videoCommandState : "videoCommandContainer" | "videoCommandContainerClosed"
}

const VideoPlayer = (props : IVideoPlayerConfiguration) => {

    const route = useNavigate();
    // States

    const [getUrl, setUrl] = useState<string>("noUrl");
    const [isMouseMoved, setMouseMoved] = useState<boolean>(false);
    const [getDuration,setDuration] = useState()
    const [seed, setSeed] = useState<number>(1);

    // screens
    const [isMobile, setIsMobile] = useState<boolean>(false);

    // Refs
    const videoRef = useRef<HTMLVideoElement>(null);
    const stylesState = useRef<styleState>({
        videoBarstate: "videoBarClosed",
        videoCommandState: "videoCommandContainerClosed"
    });

    useEffect(() => {
        setSeed(Math.random());
        setIsMobile(isBrowserOnMobile());
    }, []);
    useEffect(() => {
        setTimeout(() => {
            setUrl(props.url);
        },500);
    }, [props.url]);
    useEffect(() => {

        if (!isMouseMoved) {
            stylesState.current.videoCommandState = "videoCommandContainerClosed";
            stylesState.current.videoBarstate = "videoBarClosed";
            return;
        }

        setTimeout(() => {
            setMouseMoved(false);
        }, 7000);
    }, [isMouseMoved]);
    useEffect(() => {
        if (isMobile) {
            alert("Per favore girare il telefono in orizzontale");
        }
    }, [isMobile]);

    const isBrowserOnMobile = (): boolean => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    const goBack = () => {
        route("/videoList");
    }

    const canDrawButtons = (): boolean => {
        const canDraw = props.choosableButtons !== undefined && props.choosableButtons.length > 0;
        if(canDraw && isMobile){
            alert("Per favore esci dalla modalità fullscreen per scegliere.");
        }

        return canDraw;
    }

    const drawSkipButton = (): ReactElement => {
        return (
            props.goToChooseEnabled ? <SkipNext className={"skipNextIcon"} onClick={() => {
                props?.onGoToChoose()
            }} htmlColor={"white"}/> : <SkipNext htmlColor={"grey"}/>
        )
    }

    const handleDuration = () :number => {
        if(videoRef.current === null)
            return 0;

        return videoRef.current.duration;
    }

    return (
        <div>
            <video autoPlay
                   ref={videoRef}
                   src={getUrl}
                   playsInline={true}
                   title={props.title}
                   style={{position: "absolute", top: "0", bottom: "0", right: "0", left: "0"}}
                   width={"100%"}
                   height={"100%"}
                   controls={true}
                   onLoadedMetadata={() => {
                       props.onVideoReady(handleDuration());
                   }}
                   onEnded={() => {
                       props.onVideoEnded()
                   }}
            >
            </video>
            {
                canDrawButtons() ? <AutoContainer useInlineStyle={false}
                                                  style={isMobile ? choosableButtonsContainerMobile : choosableButtonsContainerNormal}>
                    {props.choosableButtons}
                </AutoContainer> : <React.Fragment/>
            }
        </div>
    )
}

export default VideoPlayer;