import {cardTheme, contentTheme} from "../../atoms/customCard/customCard";
import {Card, CardContent, CardMedia, Paper, SxProps, Theme} from "@mui/material";
import Text from "../../atoms/text/text";
import React, {Fragment} from "react";
import {Requests} from "../../../utils/Requests";
import templateImage from '../../../assets/images/BlurryBg.svg';

export interface IVideoConfig {
    id : string,
    startWithActionId : string,
    title : string,
    onClick : any,
    imageId ?: string
}

const cardThemeOverride : SxProps<Theme> = {
    ...cardTheme,
    marginTop : "2rem",
    marginInline : "auto",
    flexShrink : 0,
    transition : 'transform 1s',
    transform : 'scale(1,1)',
    ":hover" : {
        cursor : "pointer",
        transition : 'transform 1s',
        transform : 'scale(1.3,1.3)'
    }
}

const imageCss : SxProps<Theme> = {
    height : "0",
    objectFit : "fill"
}

export const VideoCard = (props : IVideoConfig) => {

    const getImage = () => {
        return props.imageId ? Requests.backendUrl + Requests.contentController + props.imageId : templateImage;
    }

    return (
        <Card sx={cardThemeOverride} onClick={() => props.onClick(props)}>
            <CardMedia component={"img"}
                       sx={imageCss}
                       image={getImage()}/>
            <CardContent sx={contentTheme}>
                <Text text={props.title} fontSize={15}/>
            </CardContent>
        </Card>
    )
}