import React, {ChangeEvent, CSSProperties, useContext, useEffect, useRef} from "react";
import "./styles.css";
import Text from "../../atoms/text/text";
import {Panel} from "../../molecules/panel/panel";
import {InputField} from "../../atoms/inputField/inputField";
import {CustomButton} from "../../atoms/customButton/customButton";
import {TextButton} from "../../atoms/textButton/textButton";
import {Requests} from "../../../utils/Requests";
import {LoginRequestDto} from "../../../dto/auth/LoginRequestDto";
import {LoginResponseDto} from "../../../dto/auth/LoginResponseDto";
import {LoginPageState, LoginSignupContext} from "../../../contexts/loginSignupContext";
import {AutoContainer, InlineRow} from "../../atoms/autoContainer/autoContainer";
import {NotificationContext} from "../../../contexts/notificationContext";
import {StringUtils} from "../../../utils/stringUtils";
import {useDispatch, useSelector} from "react-redux";
import {IAuthData, login} from "../../../reducers/Auth/authReducer";
import { useNavigate } from "react-router-dom";

const LoginPanel = () => {

    const email = useRef("");
    const password = useRef("");

    // Contexts
    const loginContext = useContext(LoginSignupContext);
    const notificationContext = useContext(NotificationContext);

    // Reducers
    // @ts-ignore
    const authReducer = useSelector((state: IAuthData) => state.auth) as IAuthData;
    // @ts-ignore
    const authDispatch = useDispatch();
    // Router
    const route = useNavigate();

    const titleStyle : CSSProperties = {
        fontSize: "32px",
        color : "white",
        lineHeight: "76px",
        fontWeight: "800",
        letterSpacing: "0.035em",
        marginTop: "0.35em",
        marginBottom: "0.4em"
    }

    // Add listener for enter key
    useEffect(() => {
        const listener = async (event: { code: string; preventDefault: () => void; }) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                await doLogin();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    useEffect(() => {
        setTimeout(async () => {
          const userJson = localStorage.getItem("user");
          const user : IAuthData = userJson !== null ? JSON.parse(userJson) : undefined;

          if(user === undefined)
              return;

          const isValid = await checkToken();

          if(isValid) {
              authDispatch(login(user));
              route("/videoList");
          }
        },500);

    },[]);

    const setEmailState = (event: ChangeEvent<string>) => {
        // @ts-ignore
        email.current = event.target.value;
    }

    const setPasswordState = (event: ChangeEvent<string>) => {
        // @ts-ignore
        password.current = event.target.value;
    }

    const switchPageState = () => {
        loginContext.switchContext(LoginPageState.signUp);
    }

    const checkToken = async () : Promise<boolean> => {
        const data = {
            email : authReducer.email,
            token : authReducer.jwt
        }
        const result = await Requests.post<any>(Requests.checkJwt,data);

        return (result.data.result == "true");
    }

    const doLogin = async () => {

        if(!StringUtils.emailValidation(email.current)) {
            notificationContext.setNotification?.("Errore","Email non valida", "error");
            return;
        }

        const dtoRequest: LoginRequestDto = {
            email: email.current,
            password: password.current
        }

        await Requests.post<LoginResponseDto>(Requests.login, dtoRequest)
            .then(value => {

                if (value === undefined)
                    return;

                const authDataSave : IAuthData = {
                    jwt : value.data.token,
                    id : value.data.id,
                    email : value.data.email
                }

                authDispatch(login(authDataSave));
                route("/videoList");
            });
    }

    return (
        <Panel>
            <Text text={"[ACCESSO]"} fontSize={28} color={"#FFFFFF"} style={titleStyle}/>
            <AutoContainer useInlineStyle={false}>
                <InputField type={"text"} textTop={"Email"} onChange={setEmailState}/>
                <InputField type={"password"} textTop={"Password"} onChange={setPasswordState}/>
                <AutoContainer cssId={"LoginContainer"} useInlineStyle={false}>
                    <CustomButton text={"ACCEDI"} fontSize={"1em"} textColor={"white"} onClick={async () => doLogin()}/>
                    <TextButton text={"REGISTRATI"}
                                fontSize={"0.98em"}
                                color={"white"}
                                onClick={switchPageState}/>
                </AutoContainer>
                <AutoContainer cssId={"GuestContainer"} useInlineStyle={true} style={InlineRow}>
                    <CustomButton cssId={"GuestButton"} text={"Accedi come ospite"} fontSize={12} textColor={"white"}/>
                </AutoContainer>
            </AutoContainer>
        </Panel>
    );
}

export default LoginPanel;
