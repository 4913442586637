import {CustomButton} from "../../customButton/customButton";
import './styles.css';

export interface IMenuItemProps {
    text : string,
    fontSize : string,
    onClick : any
}

const ProfileHamburgerMenuItem = (props : IMenuItemProps) => {
    return(
        <CustomButton cssId={"ProfileHamburgerMenuItem"}
                      text={props.text}
                      textColor={"white"}
                      fontSize={props.fontSize}
                      onClick={props.onClick} />
    )
}

export default ProfileHamburgerMenuItem;