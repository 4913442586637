import React from 'react';
import './styles.css';
import Text from "../text/text";

export interface IInputFieldProps {
    type : React.HTMLInputTypeAttribute;
    textTop : string;
    onChange : any;
    cssId? : string;
}

export const InputField = (props: IInputFieldProps) => {
    return(
        <div id={props.cssId} className={"CustomInputContainer"}>
            <Text text={props.textTop} fontSize={15} color={"white"}/>
            <input className={"CustomInput"} type={props.type} onChange={props.onChange}/>
        </div>
    );
};