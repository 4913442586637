import {CSSProperties} from "react";

export interface ITextProps {
    text : string,
    fontSize : number | string,
    color ?: string,
    style ? : CSSProperties
}

const Text = (props : ITextProps) => {

    let styleDefinitive : CSSProperties;

    styleDefinitive = {
        ...props.style,
        fontSize : props.fontSize,
        color : props.color === undefined ? "black" : props.color
    }

    return (
        <h1 style={styleDefinitive}>{props.text}</h1>
    );
}

export default Text;