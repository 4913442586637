import * as faceapi from "face-api.js";
import {useEffect, useRef, useState} from "react";
import './styles.css';
import {AutoContainer, InlineColumn} from "../../atoms/autoContainer/autoContainer";

export interface IEmotionData {
    emotionName : string,
    weight : number
}

export interface IFaceDetectorProps {
    onEmotionCaptured : any,
    canRun : boolean,
    onError : any
}

export const FaceDetector = (props : IFaceDetectorProps) => {

    const videoRef = useRef<HTMLVideoElement | undefined>();
    const canvasRef = useRef<any>();
    const averageEmotions = useRef<Array<IEmotionData>>([]);
    const intervalId = useRef<NodeJS.Timer>();

    const [canRun, setCanRun] = useState<boolean>(false);

    // OnStart
    useEffect(() => {
        console.log("Initializing face detector, props run equal to " + props.canRun + " state run equal to " + canRun);

        const diff = booleanCompare(props.canRun, canRun);
        if (!diff) {
            if (props.canRun) {
                setCanRun(true);
            } else {
                setCanRun(false);
            }

        }
    }, [props]);
    // OnDestroy
    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if (canRun) {
            videoRef && loadModels();
        } else {
            reset();
        }
    }, [canRun]);

    const booleanCompare = (a: boolean, b: boolean): boolean => {
        return Number(a) === Number(b);
    }

    const reset = async () => {
        console.log("Chiudo la webcam")
        averageEmotions.current = [];
        if (intervalId !== undefined && intervalId.current !== undefined) {
            clearInterval(intervalId.current);
            if (videoRef && videoRef.current && videoRef.current.srcObject) {
                // @ts-ignore
                stopVideo(videoRef.current.srcObject);
                canvasRef.current = null;
            }
        }
    };

    const loadModels = () => {
        const url = process.env.REACT_APP_MODELS_PATH;
        console.log("Loading models from " + url)
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri(url),
            //faceapi.nets.faceLandmark68Net.loadFromUri(url),
            faceapi.nets.faceRecognitionNet.loadFromUri(url),
            faceapi.nets.faceExpressionNet.loadFromUri(url),
        ])
            .then(async () => {
                console.log("Models loaded")
                await startVideo();
                //await faceDetection();
            })
            .catch(err => {
                console.log("Models cannot be loaded error FACE API " + err);
                alert(err);
            })
    };

    const startVideo = async () => {

        if (!canRun)
            return;

        if (navigator === undefined || navigator.mediaDevices === undefined) {
            console.log("No media devices found");
            return;
        }

        const stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                width: 256,
                height: 256
            }
        });

        if (!canRun) {
            return;
        }

        if (stream && videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.onloadeddata = function (e) {
                console.log("VIDEO LOADED " + e);
                faceDetection();
            }
        }
    }

    const stopVideo = (stream: MediaStream | undefined | null) => {

        if (!stream) {
            return;
        }

        const tracks = stream.getTracks();
        if (tracks) {
            tracks.forEach((track) => {
                console.log("Track " + track);
                track.stop();
            })
        }

        canvasRef.current = null;
    }

    const faceDetection = async () => {

        console.log("Can detector run ?? : " + canRun)

        if (!canRun)
            return;

        intervalId.current = setInterval(async () => {

            console.log("Can Run " + canRun);

            if (!canRun || !canvasRef.current) {
                console.log("Cannot run anymore");
                clearInterval(intervalId.current);
                return;
            }

            if (videoRef === undefined || videoRef.current === undefined) {
                console.log("Video image not exist");
                return;
            }

            const detection = await faceapi.detectSingleFace(videoRef.current,
                new faceapi.TinyFaceDetectorOptions({
                    inputSize: 256,
                    scoreThreshold: 0.35
                }))
                //.withFaceLandmarks()
                .withFaceExpressions();
            if (detection === undefined) {
                console.log("Detection undefined ")
                return;
            }
            canvasRef.current.innerHtml = await faceapi.createCanvasFromMedia(videoRef.current);
            faceapi.matchDimensions(canvasRef.current, {
                width: 256,
                height: 256,
            })
            const resized = faceapi.resizeResults(detection, {
                width: 256,
                height: 256,
            });
            // to draw the detection onto the detected face i.e the box
            faceapi.draw.drawDetections(canvasRef.current, resized);
            //to draw the the points onto the detected face
            //faceapi.draw.drawFaceLandmarks(canvasRef.current, resized);
            //to analyze and output the current expression by the detected face
            faceapi.draw.drawFaceExpressions(canvasRef.current, resized);

            console.log("Calculating media...");
            calculateMedia(detection);
        }, 300)
    }

    const calculateMedia = (value: any) => {
        const emotions: Array<IEmotionData> = [];
        Object.entries<any>(value.expressions).forEach(value => {
            const emotionData: IEmotionData = {
                emotionName: value[0],
                weight: value[1]
            }
            emotions.push(emotionData);
        });

        var correctEmotion = emotions[0];
        for (let i = 0; i < emotions.length; i++) {
            if (emotions[i].weight > correctEmotion.weight) {
                correctEmotion = emotions[i];
            }
        }

        averageEmotions.current.push(correctEmotion);

        console.log(" Average emotions count [" + averageEmotions.current.length + "] " + "Last Emotion " + correctEmotion.emotionName)

        if (averageEmotions.current.length >= 15) {
            const averageEmotion = getAverageEmotion();
            console.log("AVERAGE EMOTION IS : " + averageEmotion);
            props.onEmotionCaptured?.(averageEmotion);
            averageEmotions.current = [];
            setCanRun(false);
        }
        console.log("Emotion detected " + correctEmotion.emotionName + " Weight " + correctEmotion.weight);
    }

    const getAverageEmotion = (): string => {
        const happyCount = averageEmotions.current.filter(emotion => emotion.emotionName.toLowerCase() === "happy").length;
        const sadCount = averageEmotions.current.filter(emotion => emotion.emotionName.toLowerCase() === "sad").length;
        const neutralCount = averageEmotions.current.filter(emotion => emotion.emotionName.toLowerCase() === "neutral").length;
        const surprisedCount = averageEmotions.current.filter(emotion => emotion.emotionName.toLowerCase() === "surprised").length;
        const angryCount = averageEmotions.current.filter(emotion => emotion.emotionName.toLowerCase() === "angry").length;

        if (happyCount > sadCount &&
            happyCount > neutralCount &&
            happyCount > surprisedCount &&
            happyCount > angryCount)
            return "happy";
        if (sadCount > happyCount &&
            sadCount > neutralCount &&
            sadCount > surprisedCount &&
            sadCount > angryCount)
            return "sad";
        if (neutralCount > happyCount &&
            neutralCount > sadCount &&
            neutralCount > surprisedCount &&
            neutralCount > angryCount)
            return "neutral";
        if (surprisedCount > happyCount &&
            surprisedCount > sadCount &&
            surprisedCount > neutralCount &&
            surprisedCount > angryCount)
            return "surprised";

        return "";
    }

    if (canRun) {
        return (
            <AutoContainer useInlineStyle={true} style={InlineColumn}>
                <div className="detector">
                    <div className='detector_video'>
                        <video
                            style={{
                                display: "none",
                                flexWrap: "nowrap",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            crossOrigin='anonymous'
                            // @ts-ignore
                            ref={videoRef}
                            autoPlay={true}/>
                        <canvas ref={canvasRef} width="256" height="256" className='detector_canvas'/>
                    </div>
                </div>

            </AutoContainer>
        );
    }
    else {
        return (
            <AutoContainer useInlineStyle={true} style={InlineColumn}>

            </AutoContainer>
        );
    }
}