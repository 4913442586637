import {Avatar} from "@mui/material";
import './styles.css';

export interface IProfileProps {
  name: string,
  ariaId ?: string,
  onClick : any
}

export const ProfileButton = (props : IProfileProps) => {

  const stringToColor = (string: string) : string => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const stringAvatar = (name: string) => {

    return {
      sx: {
        bgcolor: stringToColor(name),
        width : 42,
        height : 42
      },
      onClick : props.onClick,
      children: props.name.charAt(0) // NOME COGNOME : Matteo Vilardo `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return(
      <div className={"profileButton"}>
        <Avatar aria-controls={props?.ariaId} {...stringAvatar(props.name)}/>
      </div>
  );
}
