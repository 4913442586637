import React, {useContext, useEffect} from 'react';
import './styles.css';
import LoginPanel from "../../components/organisms/loginPanel/loginPanel";
import {HeaderBar} from "../../components/organisms/headerBar/headerBar";
import {TextButton} from "../../components/atoms/textButton/textButton";
import {CustomButton} from "../../components/atoms/customButton/customButton";
import {LoginPageState, LoginSignupContext} from "../../contexts/loginSignupContext";
import {RegisterPanel} from "../../components/organisms/registerPanel/registerPanel";

const LoginPage = () => {

    const context = useContext(LoginSignupContext);

    const switchToPage = (state: LoginPageState) => {
        context.switchContext(state);
    }

    return (
        <React.Fragment>
            <div className={"Container"}>
                {context.state === LoginPageState.login
                    ? <LoginPanel/>
                    : <RegisterPanel/>
                }
            </div>
            <div className={"Background"}>
                <HeaderBar>
                    <TextButton text={"Registrati"}
                                fontSize={"0.9rem"}
                                color={"white"}
                                onClick={() => switchToPage(LoginPageState.signUp)}/>
                    <CustomButton text={"[Accedi]"}
                                  fontSize={"0.98rem"}
                                  textColor={"white"}
                                  onClick={async () => switchToPage(LoginPageState.login)}/>
                </HeaderBar>
            </div>
        </React.Fragment>
    );
}

export default LoginPage;
