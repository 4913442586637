import React, {Fragment, ReactElement, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {HeaderBar} from "../../components/organisms/headerBar/headerBar";
import {NotificationContext} from "../../contexts/notificationContext";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../index";
import {useNavigate} from "react-router-dom";
import {ResponseVideoActionContainerDto} from "../../dto/video/responseVideoActionContainerDto";
import {Requests} from "../../utils/Requests";
import {configureVideo} from "../../reducers/Video/videoReducer";
import {IVideoConfig, VideoCard} from "../../components/molecules/videoCard/videoCard";
import {NumberUtils} from "../../utils/numberUtils";
import {ProfileHamburger} from "../../components/molecules/profileHamburger/profileHamburger";
import ProfileHamburgerMenuItem from "../../components/atoms/menuItems/profileHamburgerMenuItem/profileHamburgerMenuItem";
import './styles.css';
import {logout} from "../../reducers/Auth/authReducer";
import {CarouselVideo} from "../../components/organisms/carousel/carouselVideo";
import {CustomPaper} from "../../components/atoms/customPaper/CustomPaper";
import Text from "../../components/atoms/text/text";


export const VideoList = () => {

    const [getVideoList, setVideoList] = useState<Array<ResponseVideoActionContainerDto>>([]);
    const [getVideoElements, setVideoElements] = useState<Array<ReactElement>>([]);

    const notificationContext = useContext(NotificationContext);

    // @ts-ignore
    const authSelector = useSelector((state: RootState) => state.auth.value);

    const authDispatch = useDispatch();
    const videoDispatch = useDispatch();

    // Router
    const route = useNavigate();

    useEffect(() => {

        if (authSelector === undefined || authSelector === null) {
            route("/");
            return;
        }

        notificationContext.setNotification?.(
            "Benvenuto",
            `Salve ${authSelector?.email}`,
            "success"
        );
        applyList();

    }, []);

    useEffect(() => {
        renderVideoCard();
    },[getVideoList]);

    const applyList = async () => {
        const list = await fetchData();
        if (list === undefined)
            return;
        setVideoList(list);
    }

    const fetchData = async (): Promise<Array<ResponseVideoActionContainerDto> | undefined> => {
        const response = await Requests.get<Array<ResponseVideoActionContainerDto>>(Requests.videoActionContainerController, authSelector?.jwt);
        if (response.data === undefined) {
            return undefined;
        }
        return response.data;
    }

    const renderVideoCard = () : void => {

        if(getVideoList.length <= 0) {
            return;
        }

        const arr = getVideoList.map(v => <VideoCard title={v.videoName}
                                                key={v.id + NumberUtils.generateRandomNumber(-9999, 9999)}
                                                id={v.id}
                                                startWithActionId={v.startWithActionId}
                                                imageId={v.imageId}
                                                onClick={filmSelection}/>);

        let overrideIndex = 3;
        for (overrideIndex; overrideIndex < 10; overrideIndex++) {
            arr[overrideIndex] =
                <CustomPaper>
                    <Text text={"Coming soon"} color={'white'} fontSize={'1rem'} style={{textAlign : "center", paddingTop : '2.5rem'}}/>
                </CustomPaper>
        }

        setVideoElements(arr);
    }

    const filmSelection = (config: IVideoConfig) => {
        console.log("Film selected " + config.title)
        const data = getVideoList.find(m => m.id === config.id);
        if (data === undefined) {
            notificationContext.setNotification?.("Errore", "Errore durante la riproduzione", "error");
            return;
        }
        videoDispatch(configureVideo({data: data}));

        //const params = new URLSearchParams({id : data.id});

        route(`/videoPlayer?id=${data.startWithActionId}&title=${data.videoName}`,{state:{id:data.startWithActionId,title:data.videoName}});
    }

    const logoutAction = () => {
        authDispatch(logout());
        route("/")
    }

    const getMenuItems = (): Array<ReactElement> => {
        return [
            <ProfileHamburgerMenuItem text={"Logout"} fontSize={"0.85rem"} onClick={logoutAction}/>
        ]
    }

    return (
        <div className={"Background"}>
            <HeaderBar>
                <ProfileHamburger onClick={undefined} menuItems={getMenuItems()}/>
            </HeaderBar>
            <CarouselVideo videoList={getVideoList}>
                {getVideoElements}
            </CarouselVideo>
        </div>
    )
}

/*
 <AutoContainer useInlineStyle={true} style={InlineRow}>
                    {getVideoList !== undefined ? renderVideoCard() : React.Fragment}
                </AutoContainer>
 */