import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.css';
import App from "./App";
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import { Provider } from 'react-redux';
import AuthReducer from "./reducers/Auth/authReducer";
import VideoReducer from "./reducers/Video/videoReducer";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const combined = combineReducers({
    auth : AuthReducer,
    video : VideoReducer
})
const store = configureStore({
    reducer : combined
});

export type RootState = ReturnType<typeof store.getState>

root.render(
    <Provider store={store}>
        <HelmetProvider>
            <Helmet>
                <title>Logic script</title>
            </Helmet>
            <App/>
        </HelmetProvider>

    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
