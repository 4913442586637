import React, {CSSProperties} from "react";
import "./styles.css";

export interface IContainerProps {
    children : JSX.Element | JSX.Element[],
    useInlineStyle : boolean,
    cssId ? : string,
    style ? : CSSProperties,
    onMouseHover ? : React.MouseEventHandler<HTMLDivElement>,
    onMouseMove ? : React.MouseEventHandler<HTMLDivElement>,
    ref ? : Element | any
}

export let InlineRow : CSSProperties = {
    display : "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    justifyContent : "center",
    width : "100%",
    height : "auto"
}

export let InlineColumn : CSSProperties = {
    display : "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent : "stretch",
    width: "100%",
    height: "auto"
}

export const AutoContainer = (props : IContainerProps) => {

    const style = props.style;

    if(props.useInlineStyle)
    return(
        <div ref={props.ref}
             onMouseMove={props.onMouseMove}
             onMouseEnter={props.onMouseHover}
             id={props.cssId}
             style={style}>{props.children}</div>
    )
    else
        return (
            <div ref={props.ref}
                 onMouseMove={props.onMouseMove}
                 onMouseEnter={props.onMouseHover}
                 id={props.cssId}
                 style={style}
                 className={"AutoContainer"}>{props.children}</div>
        )
}