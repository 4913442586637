import React, {createContext} from "react";

export enum LoginPageState {
    login,
    signUp
}

const pageState = {
    state: LoginPageState.login,
    switchContext : (newState : LoginPageState) => {}
};

export const LoginSignupContext = createContext(pageState);