import "./styles.css";
import image from "../../../assets/images/Logic_script.svg";
import React from "react";

export interface HeaderProps {
    children : any
}

export const HeaderBar = (props : HeaderProps) => {
    return (
        <div className={"HeaderBar"}>
            <img src={image} style={{paddingLeft: "2em"}}/>
            <div className={"HeaderBarContent"}>
                {props.children}
            </div>
        </div>
    )
}