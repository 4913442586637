import React, {Fragment, ReactElement, useEffect, useRef, useState} from 'react';
import {IconButton, SxProps, Theme} from "@mui/material";
import {CustomPaper} from "../../atoms/customPaper/CustomPaper";
import Slider from "react-slick";
import {ArrowBackOutlined, ArrowForwardOutlined} from "@mui/icons-material";
import Text from "../../atoms/text/text";
import {ResponseVideoActionContainerDto} from "../../../dto/video/responseVideoActionContainerDto";
import {AutoContainer, InlineColumn, InlineRow} from "../../atoms/autoContainer/autoContainer";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.css';

export interface ICarouselVideoProps {
    children : ReactElement [],
    videoList : Array<ResponseVideoActionContainerDto>
}

const prevActiveTheme  : SxProps<Theme>= {
    '&.MuiIconButton-root': {
        margin: 'auto',
        left: '0',
        display : 'block',
        opacity: '1',
        marginInline: '0.5rem',
        zIndex: 90
    }
};
const prevDisabledTheme : SxProps<Theme> = {
    '&.MuiIconButton-root': {
        margin: 'auto',
        left: '0',
        display : 'block',
        opacity: '1',
        marginInline: '0.5rem',
        zIndex: 90,
        fillOpacity : '0.4'
    }
};
const nextActiveTheme  : SxProps<Theme>= {
    '&.MuiIconButton-root': {
        margin: 'auto',
        right: '0',
        display : 'block',
        opacity: '1',
        marginInline: '0.5rem',
        zIndex: 90
    }
};
const nextDisabledTheme : SxProps<Theme>= {
    '&.MuiIconButton-root': {
        margin: 'auto',
        right: '0',
        display : 'block',
        opacity: '1',
        marginInline: '0.5rem',
        zIndex: 90,
        fillOpacity : '0.4'
    }
};

export const CarouselVideo = (props : ICarouselVideoProps) => {

    // video Slider
    const slideToScroll = 5;
    const slidesToShow = 5;
    const sliderRef = useRef<any>();
    const [prevButtonStyle, setPrevButtonStyle] = useState<SxProps<Theme>>();
    const [nextButtonStyle, setNextButtonStyle] = useState<SxProps<Theme>>();
    const [currentSlidePage, setCurrentSlidePage] = useState<number>(0);

    useEffect(() => {
        if (!props.children || props.children.length <= 0) {
            console.warn("video list data is null or length is 0");
            return;
        }
        slideChanged(0);
    }, [props.videoList, props.children]);

    useEffect(() => {

        if (currentSlidePage >= getMaxPages()) {
            setNextButtonStyle(nextDisabledTheme);
            setPrevButtonStyle(prevActiveTheme);
            return;
        } else if (currentSlidePage <= 1) {
            setPrevButtonStyle(prevDisabledTheme);
            setNextButtonStyle(nextActiveTheme);
            return;
        } else {
            setNextButtonStyle(nextActiveTheme);
            setPrevButtonStyle(prevActiveTheme);
        }

    }, [currentSlidePage]);

    const DrawPrevButton = (): ReactElement => {
        return (<IconButton color="primary" sx={prevButtonStyle} onClick={slidePrev}>
            <ArrowBackOutlined/>
        </IconButton>)
    }

    const DrawNextButton = (): ReactElement => {
        return (<IconButton color="primary" sx={nextButtonStyle} onClick={slideNext}>
            <ArrowForwardOutlined/>
        </IconButton>)
    }

    const slideNext = () => {
        if (currentSlidePage >= getMaxPages())
            return;

        sliderRef?.current?.slickNext();
    }

    const slidePrev = () => {
        if (currentSlidePage <= 1)
            return;

        sliderRef?.current?.slickPrev();
    }

    const getMaxPages = (): number => {
        return props.children.length / slidesToShow;
    }

    const getVideoItems = (): ReactElement[] => {
        var items = new Array<ReactElement>();

        for (let i = 0; i < 10; i++) {
            items[i] =
                <CustomPaper>
                    <Text text={i.toString()} fontSize={"5rem"}/>
                </CustomPaper>
        }
        return items;
    }

    const slideChanged = (currentSlide: number) => {

        const currentPage = (currentSlide / slidesToShow) + 1;

        setCurrentSlidePage(currentPage);
    }

    if (!props.children || props.children.length <= 0) {
        return (
            <Fragment/>
        )
    }

    return (
        <AutoContainer useInlineStyle={true}
                       style={{
                           ...InlineColumn,
                           alignItems: "normal",
                           flexWrap: "nowrap",
                           boxShadow: 'inset 1rem 20rem 20rem 20px #46404030'
                       }}>
            <Text text={"Movie Logic"}
                  fontSize={'2.25rem'}
                  color={'white'}
                  style={{marginInline: '3.15rem'}}/>
            <AutoContainer useInlineStyle={true}
                           style={{
                               ...InlineRow,
                               alignItems: "normal",
                               flexWrap: "nowrap",
                           }}>
                <DrawPrevButton/>
                <Slider dots={false}
                        infinite={false}
                        slidesToScroll={slideToScroll}
                        slidesToShow={slidesToShow}
                        ref={c => (sliderRef.current = c)}
                        afterChange={slideChanged}>
                    {props.children}
                </Slider>
                <DrawNextButton/>
            </AutoContainer>
        </AutoContainer>
    )
}