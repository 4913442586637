import {useIsVisible} from "../../../customHooks/useIsVisible";
import {useEffect} from "react";
import {ITextProps} from "../text/text";
import {Paper, SxProps, Theme} from "@mui/material";

export interface ICustomPaperProps {
    children ?: any,
    sx ?: SxProps<Theme>,
}

let style : SxProps<Theme> = {
    width : '8rem',
    height : '12rem',
    backgroundColor : "#302b3c",
    borderRadius : '0.2rem',
    marginInline : "auto",
    marginTop : '2rem',
    transition : 'transform 1s',
    transform : 'scale(1,1)',
    ":hover" : {
        cursor : "pointer",
        transition : 'transform 1s',
        transform : 'scale(1.3,1.3)'
    }
}

export const CustomPaper = (props : ICustomPaperProps) => {

    useEffect(() =>{
        if(props.sx){
            style = props.sx;
        }
    },[props.sx]);

    return(
        <Paper sx={style}>
            {props.children}
        </Paper>
    );
}