import {FaceDetector} from "../components/molecules/faceDetector/faceDetector";
import {AutoContainer, InlineColumn} from "../components/atoms/autoContainer/autoContainer";
import {FaceDetectorDebugger} from "../components/molecules/faceDetector/faceDetectorDebugger";

export const FaceDetectorPageTest = () => {
  return(
      <AutoContainer useInlineStyle={true} style={InlineColumn}>
        <FaceDetectorDebugger/>
      </AutoContainer>

  )
}
