import axios from 'axios';
import {useContext} from 'react'
import {ErrorDto} from "../dto/common/errorDto";
import {NotificationContext} from "../contexts/notificationContext";

const instance = axios.create({
    baseURL:  "https://example.com"
})

// @ts-ignore
const AxiosInterceptor = ({ children }) => {

    const context = useContext(NotificationContext);

    axios.interceptors.response.use(
        response => response,
        error => {
            const errorDto = error.response.data as ErrorDto;

            context.setNotification?.(
                errorDto.errorType,
                errorDto.description,
                "error",
            );
        });

    return children;
}


export default instance;
export { AxiosInterceptor }