import {createContext} from "react";
import {AlertColor} from "@mui/material/Alert/Alert";

export interface INotificationContext {
    title ?: string
    description? : string,
    severity ?: AlertColor,
    setNotification?(title : string, description : string, severity : AlertColor) : void ;
}

const notificationContext : INotificationContext = {
    title: "",
    description: "",
    severity: "info",
    setNotification(title : string, description : string, severity : AlertColor) {
        this.title = title;
        this.description = description;
        this.severity = severity;
    }
}

export const NotificationContext = createContext(notificationContext);