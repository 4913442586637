import "./styles.css";
import VideoPlayer from "../../components/molecules/videoPlayer/videoPlayer";
import {useContext, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {IVideoData} from "../../reducers/Video/videoReducer";
import {ResponseVideoActionDto} from "../../dto/video/responseVideoActionDto";
import {Requests} from "../../utils/Requests";
import {RootState} from "../../index";
import {IAuthData} from "../../reducers/Auth/authReducer";
import {NotificationContext} from "../../contexts/notificationContext";
import {FullScreen, FullScreenHandle, useFullScreenHandle} from "react-full-screen";
import {CustomButton} from "../../components/atoms/customButton/customButton";
import {NumberUtils} from "../../utils/numberUtils";
import {TimeoutId} from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import {FaceDetector} from "../../components/molecules/faceDetector/faceDetector";
import {useLocation, useNavigate} from "react-router-dom";
import VideoPlayerNew from "../../components/molecules/videoPlayerNew/videoPlayerNew";

export const VideoPage = () => {

    const [fullScreen,setFullScreenHandle] = useState<FullScreenHandle>(useFullScreenHandle());
    const [isFullScreen, setFullScreen] = useState(true);
    const [video, setVideo] = useState<ResponseVideoActionDto>();
    const [chooseVideo, setChooseVideo] = useState<ResponseVideoActionDto>();
    const [choosableButtons,setChoosableButtons] = useState<JSX.Element[]>([]);
    const [canEmotionDetectorRun,setCanEmotionDetectorRun] = useState<boolean>(false);
    const [title,setTitle] = useState<string>("");

    const chooseData = useRef<Array<ResponseVideoActionDto | undefined>>([]);
    const getDuration = useRef<number>(0);
    const getTime = useRef<number>(0);
    const nextVideo = useRef<ResponseVideoActionDto>();
    const routineId = useRef<TimeoutId>();
    const getAudio = useRef<number>(100);
    const [seed, setSeed] = useState(1);

    // @ts-ignore
    const videoSelector = useSelector((state: IVideoData) => state.video.value) as IVideoData;
    // @ts-ignore
    const authSelector = useSelector((state: RootState) => state.auth.value) as IAuthData;

    const notificationContext = useContext(NotificationContext);
    const route = useNavigate();
    const location = useLocation();

    useEffect(() => {
       setSeed(Math.random());
    },[]);

    useEffect(() => {

        if(videoSelector === undefined)
        {
            const queryParameters = new URLSearchParams(location.search)
            const id = queryParameters.get("id")
            const title = queryParameters.get("title");
            if(!id || !title) {
                notificationContext?.setNotification?.("Errore","E' stato riscontrato un errore nel video, contattare gli sviluppatori","error");
                route("/videoList")
                return;
            }
            else {
                setTitle(title);
                setVideoAsync(id);
                return;
            }
        }
        setTitle(videoSelector.data.videoName);
        setVideoAsync(videoSelector.data.startWithActionId);
    }, [videoSelector]);

    useEffect(() => {
        //setTimeout(setNextVideoAsync,1000);
        prepareVideo();
    }, [video]);

    useEffect(() => {
        if(choosableButtons === undefined || choosableButtons.length <= 0) {
            setCanEmotionDetectorRun(false)
            return;
        }
        setCanEmotionDetectorRun(true);
    },[choosableButtons]);

    useEffect(() => {
        console.log("Can emotion detector run " + canEmotionDetectorRun);
    },[canEmotionDetectorRun]);
    // page reloaded
    useEffect(() => {
        window.addEventListener("beforeunload", pageRefreshed);
        return () => {
            window.removeEventListener("beforeunload", pageRefreshed);
        };
    }, []);

    const pageRefreshed = () => {
        setSeed(Math.random());
    }

    const prepareVideo =  async () => {
        await setNextVideoAsync();
        await createChoiceButtons();
    }

    const goFullScreen = async () : Promise<boolean> => {

        console.log("Go full screen chiamato");

        if(!fullScreen) {
            console.log("Impossible to set fullscreen mode")
            return false;
        }
        setFullScreen(true);
        try {
            await fullScreen?.enter();
            alert("ORA E' in fullscreen")
            return true;
        }
        catch (e) {
            console.log(e);
            alert(e);
        }

        return false;
    }

    const exitFullScreen = async () : Promise<boolean> => {
        if(!fullScreen) {
            console.log("Impossible to exit from the fullscreen mode")
            return false;
        }

        setFullScreen(false);
        await fullScreen?.exit();
        return true;
    }

    const fullScreenChange = (state : boolean, handle : FullScreenHandle) => {
        setFullScreen(state);
        alert("is full screen active " + handle.active);
    }

    const setVideoAsync = async (id : string) => {
        const v = await fetchVideo(id);
        if (v === undefined)
            return;
        setVideo(v);
    }

    const setNextVideoAsync = async () => {
        if (video?.onlyOneSequence === true) {
            const id = video.actionLinks[0].idActionData;
            nextVideo.current = await fetchVideo(id);
        }
    }

    const fetchVideo = async (id: string): Promise<ResponseVideoActionDto | undefined> => {

        const url = Requests.videoActionController + id;
        const responseVideo = await Requests.get<ResponseVideoActionDto>(url, authSelector);

        if (responseVideo.data === undefined) {
            notificationContext.setNotification?.("Attenzione", "Nessun video trovato", "warning");
            return undefined;
        }

        if(responseVideo.data.actionLinks.length > 1) {
            setChooseVideo(responseVideo.data)
        }

        return responseVideo.data;
    }

    const createChoiceButtons = async () => {
        if(video === undefined || video?.onlyOneSequence) {
            setChoosableButtons([]);
            return;
        }

        const buttons = [];
        chooseData.current = [];
        for (let i = 0; i < video?.actionLinks.length; i++) {
            chooseData.current[i] = await fetchVideo(video?.actionLinks[i].idActionData);
        }

        for (let i = 0; i < chooseData.current.length; i++) {
            const response = chooseData.current[i];
            if(response === undefined)
                continue;

            buttons.push(<CustomButton text={response.actionName}
                                       cssId={"PlayerButton"}
                                       textColor={"white"}
                                       onClick={() => videoPicked(response?.id)}
                                       fontSize={12}
            />);
        }

        setChoosableButtons(buttons);
    }

    const randomPickRoutine = () => {
        if(routineId.current !== undefined){
            console.log("Routine already started will return");
            return;
        }

        const waitTime = (getDuration.current * 1000) - 500;
        console.log("RANDOM ROUTINE STARTED __ routine time __ " + waitTime)
        routineId.current = setTimeout(()=> {
            const index = NumberUtils.generateRandomNumber(0,chooseData.current.length - 1);
            const id = chooseData.current[index]?.id;
            if(id === undefined)
                return;
            console.log("Random Routine Completed !!!")
            videoPicked(id);
        },waitTime);
    }

    const audioChanged = (value : number) => {
        getAudio.current = value;
    }

    const videoPicked = async (id : string) => {
        clearTimeout(routineId.current);
        routineId.current = undefined;
        await setVideoAsync(id);
        nextVideo.current = undefined;
    }

    const videoReady = (duration : number) => {
        console.log("video is ready callback arrived " + duration);
        getDuration.current = duration;
        getTime.current = 0;
        if(choosableButtons.length > 0) {
            randomPickRoutine();
        }
    }

    const videoEnded = () => {
        console.log("VIDEO ENDED")
        getTime.current = 0;
        if(nextVideo === undefined || nextVideo.current === undefined)
            return;
        setVideo(nextVideo.current);
    }

    const timeUpdated = (value : number) => {
        getTime.current = value / 100 * getDuration.current;
    }

    const goToChooseVideo = () => {
        if(chooseVideo === undefined)
            return;

        setVideo(chooseVideo);
    }

    const chooseVideoByEmotion = (data : string) => {

        if(chooseData.current == undefined || chooseData.current.length <= 0)
            return;

        if(data === undefined || data === "")
            return;

        console.log("emotion to process " + data);
        chooseData.current.forEach(d => {
                if(d === undefined || d.emotion === "") {
                    console.log("Unable to define emotion")
                    return;
                }
                if(d?.emotion.toLowerCase() === data.toLowerCase()) {
                    setVideo(d);
                    chooseData.current = [];
                }
        });
    }

    if (video === undefined || video.videoUrl === "")
        return (<div/>);

    return (
        <div>
            <VideoPlayerNew
                url={video.videoUrl}
                title={title}
                isFullScreen={isFullScreen}
                goToChooseEnabled={chooseVideo !== undefined}
                onGoToChoose={goToChooseVideo}
                choosableButtons={choosableButtons}
                onVideoReady={videoReady}
                onVideoEnded={videoEnded}
                goFullScreen={goFullScreen}
                exitFullScreen={exitFullScreen}
            />

            {canEmotionDetectorRun ? (
                <FaceDetector
                    canRun={canEmotionDetectorRun}
                    onEmotionCaptured={chooseVideoByEmotion}
                    onError={undefined}
                    key={"face.detector"}
                />
            ) : null}
        </div>
    )
}
